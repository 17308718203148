import React from 'react'
import Policy from './abuse-content.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const AbuseContent = () => {
  return (
    <Policy>
      <Policy.paragraph>
        Sitelio.com has systems in place to prevent the platform from being used
        in a manner that violates terms and conditions. We strive to make the
        web a better place and will not allow websites breaching copyright,
        attempting to take personal details, hosting unsavory content, or
        activity that violates the policies of ICANN or appropriate registry. We
        investigate and record all reports of abuse submitted to us and where
        appropriate, may take action if a violation of any of our terms of
        service is confirmed.
      </Policy.paragraph>
      <Policy.paragraph>
        We may request additional information from you about your report.
        However, please note we may not be able to provide you with specific
        updates in response to your specific report due to privacy reasons.
      </Policy.paragraph>
      <Policy.paragraph>
        We aim to have sites deemed to be breaching our terms of service removed
        within 24 hours of reporting.
      </Policy.paragraph>
      <Policy.paragraph>
        If you feel you have discovered a violation, please email us at
        <Policy.inlineLink
          data-element-location={DataElementLocations.BODY}
          data-element-label='email.abuse'
          data-element-id='sitelio-email-abuse'
          href='mailto:abuse@sitelio.com'
        >
          abuse@sitelio.com
        </Policy.inlineLink>
        .
      </Policy.paragraph>
    </Policy>
  )
}

export default AbuseContent
