import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import herobg from '../images/bing-feature.jpeg'
import StaticHero from '../components/static-hero'
import AbuseContent from '../components/abuse-content'

const Bing = () => {
  return (
    <Layout isTallFooter>
      <SEO
        title='Abuse and Copyright Violation Reporting'
        pageType='home'
        flowType='none'
      />
      <StaticHero
        imageUrl={herobg}
        title={'Report Abuse'}
        subtitle={'Abuse & Copyright Violation Reporting'}
      />
      <AbuseContent />
    </Layout>
  )
}

export default Bing
